<template>
  <div class="mx-2">
    <div>
      <template v-if="questionTest">
        <AnswerTestContainer
          :question_test_id="questionTest.id"
        ></AnswerTestContainer>
      </template>
      <h4 v-else>Este examen no ha sido creado.</h4>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "AnswerQuestionTest",
  components: {
    AnswerTestContainer: () => import("./AnswerTestContainer"),
  },
  props: {
    question_test_id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      questionTests: names.QUESTION_TESTS,
      evaluations: names.EVALUATIONS,
      sections: names.SECTIONS,
    }),
    questionTest() {
      return this.questionTests.find((x) => x.id == this.question_test_id);
    },
    evaluation() {
      if (!this.questionTest) return null;
      return this.evaluations.find((x) => x.id == this.questionTest.evaluation);
    },
  },
};
</script>

<style>
</style>
